import mergerino from 'mergerino'
import { hasAction } from './auth'
import t from '../lib/translate'

export const allOptionsMap = {
  calculationEngine: {
    at: 'Advantech-Refrion',
    luveCore: 'Lu-Ve Professional'
  },
  productType: {
    acc: 'configurator.acc',
    lc: 'configurator.lc',
    lhp: 'configurator.lhp',
    ltht: 'configurator.ltht'
  },
  range: {
    all: 'configurator.range_all',
    ecooler: 'configurator.range_ecooler',
    hv_airflow: 'configurator.range_hv_airflow',
    other: 'configurator.range_other',
    radial: 'configurator.range_radial',
    table: 'configurator.range_table',
    vshape: 'configurator.range_vshape',
    vshape_series: 'configurator.range_vshape_series',
    wall: 'configurator.range_wall'
  },
  family: {
    combo: 'configurator.family_combo',
    combo_ulcsa: 'configurator.family_combo_ulcsa',
    ecooler: 'configurator.family_ecooler',
    ecooler_container: 'configurator.family_ecooler_container',
    hv_airflow: 'configurator.family_hv_airflow',
    hv_airflow_ulcsa: 'configurator.family_hv_airflow_ulcsa',
    hv_commercial_range: 'configurator.family_hv_commercial_range',
    hv_microchannel: 'configurator.family_hv_microchannel',
    radial: 'configurator.family_radial',
    superjumbo: 'configurator.family_superjumbo',
    superjumbo_ulcsa: 'configurator.family_superjumbo_ulcsa',
    superjumbo_wall: 'configurator.family_superjumbo_wall',
    superjumbo_wall_ulcsa: 'configurator.family_superjumbo_wall_ulcsa',
    tower: 'configurator.family_tower',
    tower_ulcsa: 'configurator.family_tower_ulcsa',
    wall: 'configurator.family_wall'
  },
  condition: {
    drySystem: 'configurator.condition_dry_system',
    sprayAdiabaticSystem: 'configurator.condition_spray_adiabatic_system',
    hybridSpraySystem: 'configurator.condition_hybrid_spray_system',
    hybridSpraySystemHybrid: 'configurator.condition_hybrid_spray_system_hybrid',
    hybridSpraySystemEvolution: 'configurator.condition_hybrid_spray_system_evolution',
    industrialAdiabaticSystem: 'configurator.condition_industrial_adiabatic_system',
    industrialAdiabaticSystemWithRecirculation: 'configurator.condition_industrial_adiabatic_system_with_recirculation'
  },
  priority: {
    cooling: 'configurator.priority_cooling',
    heating: 'configurator.priority_heating',
    onlyCooling: 'configurator.priority_only_cooling',
    onlyHeating: 'configurator.priority_only_heating'
  },
  heatExchangers: {
    microchannel: 'configurator.heat_exchangers_microchannel',
    ovalTube: 'configurator.heat_exchangers_oval_tube',
    roundTube: 'configurator.heat_exchangers_round_tube'
  }
}

export const families = {
  acc: {
    vshape: [
      'tower',
      'combo',
      'wall'
    ],
    table: [
      'hv_airflow',
      'hv_microchannel',
      'hv_commercial_range'
    ],
    other: [
      'radial'
    ]
  },
  lc: {
    vshape: [
      'tower',
      'tower_ulcsa',
      'combo',
      'combo_ulcsa',
      'superjumbo',
      'superjumbo_ulcsa',
      'superjumbo_wall',
      'superjumbo_wall_ulcsa',
      'wall'
    ],
    table: [
      'hv_airflow',
      'hv_airflow_ulcsa'
    ],
    ecooler: [
      'ecooler',
      'ecooler_container'
    ],
    other: [
      'radial'
    ]
  },
  lhp: {
    vshape: [
      'tower',
      'combo',
      'superjumbo',
      'superjumbo_wall',
      'wall'
    ],
    table: [
      'hv_airflow'
    ]
  }
}

export const optionsMap = mergerino(allOptionsMap, {
  productType: {
    ltht: undefined
  },
  condition: {
    hybridSpraySystemHybrid: undefined
  }
})

export const customOptionsMap = mergerino(allOptionsMap)

export const getOptions = (prop, { isCustom = false } = {}) => {
  const optsMap = isCustom
    ? customOptionsMap
    : optionsMap
  return Object.keys(optsMap[prop])
    .map(key => ({
      value: key,
      label: t(optsMap[prop][key])
    }))
}

export const customFamilies = {
  ...families,
  ltht: {
    vshape: [
      'tower',
      'combo',
      'superjumbo',
      'wall'
    ],
    table: [
      'hv_airflow'
    ]
  }
}

// CAUTION! do not change the format of the option without adapting the parsing
// in 'getAllowedPowerSourceOptions' accordingly
export const powerSourceOptions = {
  '1ph50Hz230V': '1ph / 50Hz / 230V',
  '3ph50Hz400V': '3ph / 50Hz / 400V',
  '3ph60Hz230V': '3ph / 60Hz / 230V',
  '3ph60Hz460V': '3ph / 60Hz / 460V',
  '3ph60Hz575V': '3ph / 60Hz / 575V'
}

export const energyEfficiencyClassOptions = {
  'A+': 'A+',
  A: 'A',
  B: 'B',
  c: 'C',
  D: 'D',
  E: 'E'
}

export const getAllowedFamilies = () => Object.keys(families)
  .reduce((acc, curr) => {
    if (hasAction(`configurator.product_type_${curr}`)) {
      acc[curr] = Object.keys(families[curr])
        .reduce((accumulator, key) => {
          const allowedFamilies = families[curr][key]
            .filter(family => hasAction(`configurator.family_${curr}_${family}`))
          if (allowedFamilies.length) accumulator[key] = allowedFamilies
          return accumulator
        }, {})
    }
    return acc
  }, {})

export const getAvailableFamiliesByType = productType => {
  if (!hasAction(`configurator.product_type_${productType}`)) return {}
  const allowedFamilies = getAllowedFamilies()
  return Object.keys(allowedFamilies[productType])
    .reduce((accumulator, key) => ({
      ...accumulator,
      ...allowedFamilies[productType][key].reduce((acc, family) => {
        acc[family] = true
        return acc
      }, {})
    }), {})
}

export const getOption = entry => ({
  value: entry.id,
  label: entry.name
})

export const getFluidOption = entry => ({
  value: entry.id,
  label: entry.safetyClassName
    ? `${entry.name} (${entry.safetyClassName})`
    : entry.name
})

export const getAllowedPowerSourceOptions = () => Object.keys(powerSourceOptions)
  .reduce((acc, curr) => {
    if (hasAction(`configurator.power_source_freq_${powerSourceOptions[curr].split(' / ')[1].toLowerCase()}`)) {
      acc[curr] = powerSourceOptions[curr]
    }
    return acc
  }, {})
